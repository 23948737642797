import ErrorCodesCreate from './ErrorCodesCreate';
import ErrorCodesEdit from './ErrorCodesEdit';
import ErrorCodesIcon from '@mui/icons-material/Error';
import ErrorCodesList from './ErrorCodesList';

export default {
  list: ErrorCodesList,
  edit: ErrorCodesEdit,
  create: ErrorCodesCreate,
  icon: ErrorCodesIcon,
};
