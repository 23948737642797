import PaymentReportsCreate from './PaymentReportsCreate';
import PaymentReportsEdit from './PaymentReportsEdit';
import PaymentReportsIcon from '@mui/icons-material/AddComment';
import PaymentReportsList from './PaymentReportsList';

export default {
  list: PaymentReportsList,
  edit: PaymentReportsEdit,
  create: PaymentReportsCreate,
  icon: PaymentReportsIcon,
};
