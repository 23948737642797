import * as React from 'react';

import { Link, useGetList, useTranslate } from 'react-admin';
import { ListItem, ListItemButton, ListItemText } from '@mui/material';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import StarIcon from '@mui/icons-material/Star';
import { TopCustomer } from '../types';
import { subDays } from 'date-fns';

const TopCustomers = () => {
  const translate = useTranslate();

  const aMonthAgo = subDays(new Date(), 30);
  aMonthAgo.setDate(aMonthAgo.getDate() - 30);
  aMonthAgo.setHours(0);
  aMonthAgo.setMinutes(0);
  aMonthAgo.setSeconds(0);
  aMonthAgo.setMilliseconds(0);

  const { isLoading, data: items } = useGetList<TopCustomer>(
    'orders/list/top-customers',
    {
      filter: {
        // has_ordered: true,
        // first_seen_gte: aMonthAgo.toISOString(),
      },
      sort: { field: 'date', order: 'DESC' },
      pagination: { page: 1, perPage: 10 },
    }
  );

  return (
    <Card sx={{ flex: 1 }}>
      <CardHeader
        title={translate('pos.dashboard.top_customers')}
        subheader="за 30 дней"
      />
      <List dense={true} sx={{ display: isLoading ? 'none' : 'block' }}>
        {items
          ? items.map((record: TopCustomer) => (
              <ListItem
                to={`/customers/${record.id}`}
                component={Link}
                key={record.id}
                // style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <ListItemButton>
                  {/* <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon> */}
                  <ListItemText
                    primary={`${record.clientName} ${
                      record.companyName ? `(${record.companyName})` : ''
                    }`}
                    secondary={`${record.clientEmail}`}
                  />
                  <ListItemText
                    primary={`$${record.totalSummInUSD.toFixed(2)}`}
                    style={{ textAlign: 'right' }}
                  />
                </ListItemButton>
              </ListItem>
            ))
          : null}
      </List>
    </Card>
  );
};

export default TopCustomers;
