import * as React from 'react';

import {
  BooleanInput,
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { ISettings } from '../../types';

const Title = () => {
  const translate = useTranslate();
  return <span>{translate('resources.settings.name')}</span>;
};

const EditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const SettingsEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<Title />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput
          source="name"
          label={translate('resources.settings.fields.name')}
          validate={[required()]}
          disabled
        />
        <BooleanInput
          source="maintenance"
          label={translate('resources.settings.fields.maintenance')}
        />
        <BooleanInput
          source="usePayments"
          label={translate('resources.settings.fields.usePayments')}
        />
        <BooleanInput
          source="useBonuses"
          label={translate('resources.settings.fields.useBonuses')}
        />
        <BooleanInput
          source="useWarranty"
          label={translate('resources.settings.fields.useWarranty')}
        />
        <BooleanInput
          source="promocodeReminder"
          label={translate('resources.settings.fields.promocodeReminder')}
        />
        <TextInput
          source="novaPoshtaKey"
          label={translate('resources.settings.fields.novaPoshtaKey')}
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default SettingsEdit;
