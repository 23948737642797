import * as React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import { Order } from '../types';
import { PendingOrder } from './PendingOrder';
import { useTranslate } from 'react-admin';

interface Props {
  orders?: Order[];
}

const PendingOrders = (props: Props) => {
  const { orders = [] } = props;
  const translate = useTranslate();

  return (
    <Card sx={{ flex: 1 }}>
      <CardHeader title={translate('pos.dashboard.pending_orders')} />
      <List dense={true}>
        {orders.map((record) => (
          <PendingOrder key={record.id} order={record} />
        ))}
      </List>
    </Card>
  );
};

export default PendingOrders;
