import * as React from 'react';

import {
  BooleanField,
  DateField,
  EditButton,
  RecordContextProvider,
  TextField,
  useListContext,
  useTranslate,
} from 'react-admin';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';

import { IPrice } from '../../types';

const MobileGrid = () => {
  const translate = useTranslate();
  const { data, isLoading } = useListContext<IPrice>();

  if (isLoading || data.length === 0) {
    return null;
  }

  return (
    <Box margin="0.5em">
      {data.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
          <Card sx={{ margin: '0.5rem 0' }}>
            <CardHeader
              title={`${translate('resources.price.fields.name')} ${
                record.name
              }`}
              action={<EditButton />}
            />
            <CardContent sx={{ pt: 0 }}>
              <Typography variant="body2">
                {translate('resources.price.fields.date')}:&nbsp;
                <DateField source="date" showTime />
              </Typography>
            </CardContent>
          </Card>
        </RecordContextProvider>
      ))}
    </Box>
  );
};

export default MobileGrid;
