import * as React from 'react';

import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  useTranslate,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import MobileGrid from './MobileGrid';

const PromocodesList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const translate = useTranslate();

  return (
    <List title={translate('pos.menu.promocodes')}>
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <TextField
            source="name"
            label={translate('resources.promocodes.fields.name')}
          />
          <TextField
            source="code"
            label={translate('resources.promocodes.fields.code')}
          />
          <BooleanField
            source="isAbsoluteValue"
            label={translate('resources.promocodes.fields.isAbsoluteValue')}
          />
          <ReferenceField
            source="discountGroup"
            label={translate('resources.promocodes.fields.discountGroup')}
            reference="discounts"
          >
            <TextField source="name" />
          </ReferenceField>
          <DateField
            source="date"
            label={translate('resources.promocodes.fields.date')}
          />
          <DateField
            source="expireDate"
            label={translate('resources.promocodes.fields.expireDate')}
          />
        </Datagrid>
      )}
    </List>
  );
};

export default PromocodesList;
