import DiscountsCreate from './DiscountsCreate';
import DiscountsEdit from './DiscountsEdit';
import DiscountsIcon from '@mui/icons-material/Loyalty';
import DiscountsList from './DiscountsList';

export default {
  list: DiscountsList,
  edit: DiscountsEdit,
  create: DiscountsCreate,
  icon: DiscountsIcon,
};
