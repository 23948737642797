import * as React from 'react';

import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { format, subDays } from 'date-fns';
import { useGetList, useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';
import { Customer } from '../types';
import CustomerIcon from '@mui/icons-material/PersonAdd';
import { Link } from 'react-router-dom';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

const NewCustomers = () => {
  const translate = useTranslate();

  const aMonthAgo = subDays(new Date(), 30);
  aMonthAgo.setDate(aMonthAgo.getDate() - 30);
  aMonthAgo.setHours(0);
  aMonthAgo.setMinutes(0);
  aMonthAgo.setSeconds(0);
  aMonthAgo.setMilliseconds(0);

  const { isLoading, data: visitors } = useGetList<Customer>('customers', {
    filter: {
      has_ordered: true,
      first_seen_gte: aMonthAgo.toISOString(),
    },
    sort: { field: 'signUpDate', order: 'DESC' },
    pagination: { page: 1, perPage: 10 },
  });

  const nb = visitors ? visitors.reduce((nb: number) => ++nb, 0) : 0;
  return (
    <CardWithIcon
      to="/customers"
      icon={CustomerIcon}
      title={translate('pos.dashboard.new_customers')}
      subtitle={nb}
    >
      <List sx={{ display: isLoading ? 'none' : 'block' }}>
        {visitors
          ? visitors.map((record: Customer) => (
              <ListItem
                button
                to={`/customers/${record.id}`}
                component={Link}
                key={record.id}
              >
                <ListItemAvatar>
                  <Avatar src={`${record.avatar}?size=32x32`} />
                </ListItemAvatar>
                <ListItemText primary={`${record.name} ${record.phone}`} />
                <ListItemSecondaryAction>
                  <span>
                    {format(new Date(record.signUpDate), 'MM.dd.yyyy')}
                  </span>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          : null}
      </List>
      <Box flexGrow={1}>&nbsp;</Box>
      <Button
        sx={{ borderRadius: 0 }}
        component={Link}
        to="/customers"
        size="small"
        color="primary"
      >
        <Box p={1} sx={{ color: 'primary.main' }}>
          {translate('pos.dashboard.all_customers')}
        </Box>
      </Button>
    </CardWithIcon>
  );
};

export default NewCustomers;
