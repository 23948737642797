import * as React from 'react';

import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  Labeled,
  NumberField,
  NumberInput,
  PasswordInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  maxLength,
  maxValue,
  minLength,
  minValue,
  number,
  required,
} from 'react-admin';

import FullNameField from './FullNameField';
import { InputAdornment } from '@mui/material';

const validatePassword = [minLength(6)];

const CustomerEdit = () => {
  return (
    <Edit title={<CustomerTitle />}>
      <SimpleForm>
        <TextInput label="Имя" source="name" validate={[required()]} />
        <TextInput label="Email" source="email" style={{ width: 300 }} />
        <TextInput label="Название компании" source="companyName" />
        <TextInput label="Город" source="city" />
        <BooleanInput
          label="Сотрудник компании (доступен сервисный режим)"
          source="serviceEngineer"
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <ReferenceInput
                label="Категория цен"
                source="discount"
                reference="discounts"
                validate={[required()]}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            );
          }}
        </FormDataConsumer>
        <TextInput
          label="Номер телефона"
          source="phone"
          validate={[required(), maxLength(10), minLength(10)]}
          inputProps={{
            maxLength: 10,
          }}
        />
        <NumberInput
          label="Заказано"
          source="credit"
          max={100000000}
          min={0}
          format={(v) => parseFloat(v)}
          parse={(v) => v.toString()}
          defaultValue={0}
          validate={[number(), minValue(0), maxValue(100000000)]}
          InputProps={{
            endAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <Labeled label="Оплачено">
          <NumberField
            source="debit"
            options={{
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 2,
            }}
          />
        </Labeled>
        {/* <NumberInput
          label="Оплачено"
          source="debit"
          max={100000000}
          min={0}
          format={(v) => parseFloat(v)}
          parse={(v) => v.toString()}
          defaultValue={0}
          validate={[number(), minValue(0), maxValue(100000000)]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">грн</InputAdornment>
            ),
          }}
        /> */}
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <NumberInput
              disabled={formData.initialBonusApplied}
              label="Приветственный бонус"
              source="initialBonus"
              max={1000}
              min={0}
              format={(v) => parseFloat(v)}
              parse={(v) => v.toString()}
              defaultValue={0}
              validate={[number(), minValue(0), maxValue(1000)]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">грн</InputAdornment>
                ),
              }}
            />
          )}
        </FormDataConsumer>
        <Labeled label="Бонусов">
          <NumberField
            source="bonuses"
            options={{
              style: 'currency',
              currency: 'UAH',
            }}
          />
        </Labeled>
        <BooleanInput label="Изменить пароль" source="chPass" />
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) =>
            formData.chPass && (
              <Labeled
                label={`Введите новый пароль, затем отправьте его клиенту вручную. Пароль будет доступен для просмотра только 1 раз`}
              >
                <PasswordInput
                  label="Введите новый пароль"
                  source="password"
                  initiallyVisible
                  disabled={!formData.chPass}
                  validate={validatePassword}
                />
              </Labeled>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

const CustomerTitle = () => (
  <FullNameField size="32" sx={{ margin: '5px 0' }} />
);

export default CustomerEdit;
