import * as React from 'react';

import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { Customer, Order } from '../types';
import { useReference, useTranslate } from 'react-admin';

import { Link } from 'react-router-dom';

interface Props {
  order: Order;
}

export const PendingOrder = (props: Props) => {
  const { order } = props;
  const translate = useTranslate();
  const { referenceRecord: customer, isLoading } = useReference<Customer>({
    reference: 'customers',
    id: order.customer_id,
  });

  return (
    <ListItem button component={Link} to={`/orders/${order.id}`}>
      <ListItemAvatar>
        {isLoading ? (
          <Avatar />
        ) : (
          <Avatar
            src={`${customer?.avatar}?size=32x32`}
            sx={{
              bgcolor: 'background.paper',
            }}
          />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={new Date(order.date).toLocaleString('en-GB')}
        secondary={translate('pos.dashboard.order.items', {
          smart_count: order.items.length,
          nb_items: order.items.length,
          customer_name: customer
            ? `${customer.first_name} ${customer.last_name}`
            : '',
        })}
      />
      <ListItemSecondaryAction>
        <Box
          component="span"
          sx={{
            marginRight: '1em',
            color: 'text.primary',
          }}
        >
          ${order.totalSummInUSD.toFixed(2)}
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
