import * as React from 'react';

import {
  Edit,
  FileField,
  FileInput,
  Labeled,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { Box } from '@mui/material';
import { ICatalogsPdf } from '../../types';

const Title = () => {
  const record = useRecordContext<ICatalogsPdf>();
  return <span>PDF каталог {record ? `"${record.name}"` : ''}</span>;
};

const CatalogsPdfEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<Title />}>
      <SimpleForm>
        <TextInput
          source="name"
          label={translate('resources.catalogs_pdf.fields.name')}
          validate={[required()]}
        />
        <FileInput
          source="image"
          label={translate('resources.catalogs_pdf.fields.image')}
          accept="application/pdf"
        >
          <FileField source="image" title="title" />
        </FileInput>
        <Labeled label={translate('resources.catalogs_pdf.fields.image_curr')}>
          <FileField source="file" title="name" target="_blank" />
        </Labeled>
        <Separator />
        <NumberInput
          source="order"
          label={translate('resources.catalogs_pdf.fields.order')}
          min={1}
          max={99}
          step={1}
          validate={[required()]}
          style={{ width: 160 }}
        />
      </SimpleForm>
    </Edit>
  );
};

const Separator = () => <Box pt="1em" />;

export default CatalogsPdfEdit;
