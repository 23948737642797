import * as React from 'react';

import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ListContextProvider,
  NullableBooleanInput,
  NumberField,
  ReferenceInput,
  SearchInput,
  TextField,
  useGetList,
  useListContext,
  useTranslate,
} from 'react-admin';
import { Divider, Tab, Tabs, useMediaQuery } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';

import CustomerReferenceField from '../customers/CustomerReferenceField';
import MobileGrid from './MobileGrid';
import NbItemsField from './NbItemsField';
import SummField from './SummField';

const OrderFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput
      source="client_id"
      reference="customers"
      label="Клиент"
      perPage={10000}
    >
      <AutocompleteInput
        label="Клиент"
        optionText={(choice) =>
          choice.id // the empty choice is { id: '' }
            ? `${choice.name} (${choice.companyName})`
            : ''
        }
      />
    </ReferenceInput>
    <DateInput source="date_gte" label="Дата от" />
    <DateInput source="date_lte" label="Дата до" />
    {/* <NullableBooleanInput source="returned" label="Возвраты" /> */}
  </Filter>
);

const tabs = [
  { id: 'ordered', name: 'Заказан' },
  { id: 'delivered', name: 'Доставлен' },
  { id: 'cancelled', name: 'Отменен' },
];

const useGetTotals = (filterValues) => {
  const { total: totalOrdered } = useGetList('orders', {
    pagination: { page: 1, perPage: 25 },
    sort: { field: 'date', order: 'DESC' },
    filter: { ...filterValues, status: 'ordered' },
  });
  const { total: totalDelivered } = useGetList('orders', {
    pagination: { page: 1, perPage: 25 },
    sort: { field: 'date', order: 'DESC' },
    filter: { ...filterValues, status: 'delivered' },
  });
  const { total: totalCancelled } = useGetList('orders', {
    pagination: { page: 1, perPage: 25 },
    sort: { field: 'date', order: 'DESC' },
    filter: { ...filterValues, status: 'cancelled' },
  });

  return {
    ordered: totalOrdered,
    delivered: totalDelivered,
    cancelled: totalCancelled,
  };
};

const TabbedDatagrid = (props) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [ordered, setOrdered] = useState([]);
  const [delivered, setDelivered] = useState([]);
  const [cancelled, setCancelled] = useState([]);
  const totals = useGetTotals(filterValues);

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      // eslint-disable-next-line default-case
      switch (filterValues.status) {
        case 'ordered':
          setOrdered(ids);
          break;
        case 'delivered':
          setDelivered(ids);
          break;
        case 'cancelled':
          setCancelled(ids);
          break;
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event, value) => {
      setFilters &&
        setFilters({ ...filterValues, status: value }, displayedFilters);
    },
    [displayedFilters, filterValues, setFilters]
  );

  const selectedIds =
    filterValues.status === 'ordered'
      ? ordered
      : filterValues.status === 'delivered'
      ? delivered
      : cancelled;

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.status}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={
              totals[choice.id]
                ? `${choice.name} (${totals[choice.id]})`
                : choice.name
            }
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      {isXSmall ? (
        <ListContextProvider value={{ ...listContext, ids: selectedIds }}>
          <MobileGrid {...props} ids={selectedIds} />
        </ListContextProvider>
      ) : (
        <div>
          {filterValues.status === 'ordered' && (
            <ListContextProvider value={{ ...listContext, ids: ordered }}>
              <Datagrid
                {...props}
                optimized
                rowClick="edit"
                // isRowSelectable={() => false}
              >
                <DateField source="date" label="Дата заказа" showTime />
                <TextField source="orderId" label="Номер заказа" />
                <CustomerReferenceField label="Клиент" />
                <NbItemsField />

                <SummField
                  source="totalSummInUSD"
                  label="Сумма, $"
                  options={{
                    style: 'currency',
                    currency: 'USD',
                  }}
                  sx={{ fontWeight: 'bold' }}
                />
                {/* <NumberField
                  source="totalSumm"
                  label="Сумма, грн"
                  options={{
                    style: 'currency',
                    currency: 'UAH',
                  }}
                  sx={{ fontWeight: 'bold' }}
                /> */}
                <BooleanField source="payment" label="Оплата" />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === 'delivered' && (
            <ListContextProvider value={{ ...listContext, ids: delivered }}>
              <Datagrid {...props} rowClick="edit">
                <DateField source="date" label="Дата заказа" showTime />
                <TextField source="orderId" label="Номер заказа" />
                <CustomerReferenceField label="Клиент" />
                <NbItemsField />
                <SummField
                  source="totalSummInUSD"
                  label="Сумма, $"
                  options={{
                    style: 'currency',
                    currency: 'USD',
                  }}
                  sx={{ fontWeight: 'bold' }}
                />
                {/* <NumberField
                  source="totalSumm"
                  label="Сумма, грн"
                  options={{
                    style: 'currency',
                    currency: 'UAH',
                  }}
                  sx={{ fontWeight: 'bold' }}
                /> */}
                <BooleanField source="payment" label="Оплата" />
                {/* <BooleanField source="returned" label="Возврат" /> */}
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === 'cancelled' && (
            <ListContextProvider value={{ ...listContext, ids: cancelled }}>
              <Datagrid {...props} rowClick="edit">
                <DateField source="date" label="Дата заказа" showTime />
                <TextField source="orderId" label="Номер заказа" />
                <CustomerReferenceField label="Клиент" />
                <NbItemsField />
                <SummField
                  source="totalSummInUSD"
                  label="Сумма, $"
                  options={{
                    style: 'currency',
                    currency: 'USD',
                  }}
                  sx={{ fontWeight: 'bold' }}
                />
                {/* <NumberField
                  source="totalSumm"
                  label="Сумма, грн"
                  options={{
                    style: 'currency',
                    currency: 'UAH',
                  }}
                  sx={{ fontWeight: 'bold' }}
                /> */}
                <BooleanField source="payment" label="Оплата" />
                {/* <BooleanField source="returned" label="Возврат" /> */}
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
      )}
    </Fragment>
  );
};

const OrderList = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      filterDefaultValues={{ status: 'ordered' }}
      sort={{ field: 'date', order: 'DESC' }}
      perPage={25}
      filters={<OrderFilter />}
      title={translate('pos.menu.orders')}
      // title={props.options.label}
    >
      <TabbedDatagrid />
    </List>
  );
};

export default OrderList;
