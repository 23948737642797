import * as React from 'react';

import {
  BooleanInput,
  Create,
  DateInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  maxValue,
  minValue,
  number,
  required,
  useTranslate,
} from 'react-admin';

import { InputAdornment } from '@mui/material';
import { format } from 'date-fns';

const DiscountsCreate = () => {
  const translate = useTranslate();

  return (
    <Create>
      <SimpleForm>
        <TextInput
          label={translate('resources.promocodes.fields.name')}
          source="name"
          validate={[required()]}
          style={{ width: '100%', maxWidth: 400 }}
        />
        <TextInput
          label={translate('resources.promocodes.fields.code')}
          source="code"
          validate={[required()]}
          style={{ width: '100%', maxWidth: 400 }}
        />
        <BooleanInput
          label="Скидка в % / грн"
          source="isAbsoluteValue"
          fullWidth
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.isAbsoluteValue ? (
              <NumberInput
                label={translate('resources.promocodes.fields.absoluteValue')}
                source="absoluteValue"
                step={0.1}
                max={10000}
                min={0}
                format={(v) => parseFloat(v)}
                parse={(v) => v.toString().split('.')[0]}
                defaultValue={0}
                validate={[required(), number(), minValue(0), maxValue(10000)]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">грн</InputAdornment>
                  ),
                }}
                style={{ width: '100%', maxWidth: 400 }}
              />
            ) : (
              <NumberInput
                label={translate('resources.promocodes.fields.value')}
                source="value"
                step={0.1}
                max={100}
                min={0}
                format={(v) => parseFloat(v)}
                parse={(v) => v.toString().split('.')[0]}
                defaultValue={0}
                validate={[required(), number(), minValue(0), maxValue(100)]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                style={{ width: '100%', maxWidth: 400 }}
              />
            )
          }
        </FormDataConsumer>
        <ReferenceInput
          source="discountGroup"
          reference="discounts"
          validate={[required()]}
          perPage={1000}
        >
          <SelectInput
            optionText="name"
            label={translate('resources.promocodes.fields.discountGroup')}
            style={{ width: '100%', maxWidth: 400 }}
          />
        </ReferenceInput>
        <DateInput
          label={translate('resources.promocodes.fields.expireDate')}
          source="expireDate"
          style={{ width: '100%', maxWidth: 400 }}
          validate={[
            required(),
            minValue(format(new Date(), 'yyyy-MM-dd')),
            // maxValue(format(new Date(), 'yyyy-MM-dd')),
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

export default DiscountsCreate;
