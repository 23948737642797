import { FieldProps, useRecordContext } from 'react-admin';

import { CSSProperties } from 'react';
import { IWarranty } from '../types';

interface CustomFieldProps extends FieldProps {
  source: string;
  subSource: string;
  label: string;
}
const styles = {
  boldText: { fontWeight: 'bold', fontSize: 14 },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightText: {
    textAlign: 'right',
  },
};
export const NestedWarrantyUserField = (props: CustomFieldProps) => {
  const record = useRecordContext<IWarranty>();
  return (
    <div style={styles.flexRow as CSSProperties}>
      <div style={styles.boldText as CSSProperties}>{props.label}:</div>
      <div style={styles.rightText as CSSProperties}>
        {record && record[props.source][props.subSource]}
      </div>
    </div>
  );
};
