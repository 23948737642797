import * as React from 'react';

import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';

import { Order } from '../../types';

const Totals = () => {
  const record = useRecordContext<Order>();
  const translate = useTranslate();

  return (
    <Table sx={{ minWidth: '35em' }}>
      <TableBody>
        <TableRow>
          <TableCell>
            {translate('resources.commands.fields.items.totalSumm')}
          </TableCell>
          <TableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
            {(record?.totalSummInUSD).toLocaleString(undefined, {
              style: 'currency',
              currency: 'USD',
            })}
          </TableCell>
        </TableRow>
        {!!record?.promocodeValue && (
          <TableRow>
            <TableCell>
              {translate('resources.commands.fields.items.promocode')}{' '}
              {/* {record?.promocodeId} */}
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }}>
              {record?.promocodeValue.toLocaleString(undefined, {
                style: 'currency',
                currency: 'UAH',
              })}{' '}
              {`(${(
                record?.promocodeValue / Number(record?.items[0].rate)
              ).toLocaleString(undefined, {
                style: 'currency',
                currency: 'USD',
              })})`}
            </TableCell>
          </TableRow>
        )}
        {!!record?.bonuses && (
          <TableRow>
            <TableCell>
              {translate('resources.commands.fields.items.bonuses')}
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }}>
              {record?.bonuses.toLocaleString(undefined, {
                style: 'currency',
                currency: 'USD',
              })}
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell sx={{ fontWeight: 'bold' }}>
            {translate('resources.commands.fields.items.total')}
          </TableCell>
          <TableCell sx={{ textAlign: 'right', fontWeight: 'bold' }}>
            {(
              record?.totalSummInUSD -
              record?.promocodeValue / Number(record?.items[0].rate) -
              record?.bonuses
            ).toLocaleString(undefined, {
              style: 'currency',
              currency: 'USD',
            })}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default Totals;
