import * as React from 'react';

import {
  BooleanField,
  EditButton,
  RecordContextProvider,
  TextField,
  useListContext,
  useTranslate,
} from 'react-admin';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';

import { IPaymentReport } from '../../types';

const MobileGrid = () => {
  const translate = useTranslate();
  const { data, isLoading } = useListContext<IPaymentReport>();

  if (isLoading || data.length === 0) {
    return null;
  }

  return (
    <Box margin="0.5em">
      {data.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
          <Card sx={{ margin: '0.5rem 0' }}>
            <CardHeader title={`${record.name}`} action={<EditButton />} />
            <CardContent sx={{ pt: 0 }}>
              <Typography variant="body2">
                {translate('resources.payment_report.fields.address')}
                :&nbsp;
                <BooleanField source="address" />
              </Typography>
            </CardContent>
          </Card>
        </RecordContextProvider>
      ))}
    </Box>
  );
};

export default MobileGrid;
