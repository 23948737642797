import * as React from 'react';

import { Button, useRecordContext, useTranslate } from 'react-admin';

import { ICategory } from '../../../types';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';

const LinkToRelatedProducts = () => {
  const translate = useTranslate();
  const record = useRecordContext<ICategory>();
  return (
    <Button
      size="small"
      color="primary"
      component={Link}
      to={{
        pathname: '/items',
        search: stringify({
          page: 1,
          perPage: 25,
          filter: JSON.stringify({ serie_q: record.id }),
        }),
      }}
    >
      <>{translate('resources.series.fields.items')}</>
    </Button>
  );
};

export default LinkToRelatedProducts;
