import NotificationsCreate from './NotificationsCreate';
import NotificationsEdit from './NotificationsEdit';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsList from './NotificationsList';

export default {
  list: NotificationsList,
  edit: NotificationsEdit,
  create: NotificationsCreate,
  icon: NotificationsIcon,
};
