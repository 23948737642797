import * as React from 'react';

import {
  ArrayField,
  Datagrid,
  DateField,
  Edit,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  required,
  useRecordContext,
} from 'react-admin';

import { IExchangeRate } from '../../types';

const RateTitle = () => {
  const record = useRecordContext<IExchangeRate>();
  return <span>Валюта {record ? `"${record.name}"` : ''}</span>;
};

const RateEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const RatesEdit = () => (
  <Edit title={<RateTitle />}>
    <SimpleForm toolbar={<RateEditToolbar />}>
      <TextInput
        label="Валюта"
        source="name"
        validate={[required()]}
        disabled
      />
      <NumberInput
        label="Нал"
        source="cashRate"
        step={0.01}
        format={(v) => parseFloat(v)}
        parse={(v) => v.toString()}
        defaultValue={25}
        validate={[required()]}
      />
      <NumberInput
        label="Безнал"
        source="cashlessRate"
        step={0.01}
        format={(v) => parseFloat(v)}
        parse={(v) => v.toString()}
        defaultValue={25}
        validate={[required()]}
      />
      <ArrayField source="history" label="История">
        <Datagrid>
          <DateField source="date" label="Дата" />
          <TextField source="cashRate" label="Нал" />
          <TextField source="cashlessRate" label="Безнал" />
        </Datagrid>
      </ArrayField>
    </SimpleForm>
  </Edit>
);

export default RatesEdit;
