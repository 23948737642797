import { Card, CardContent } from '@mui/material';

import React from 'react';
import { Title } from 'react-admin';

const DeleteAccountPage = () => {
  return (
    <Card>
      <Title title="Delete user" />
      <CardContent>
        <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
          <h1>Удаление аккаунта пользователя</h1>
          <div
            style={{
              backgroundColor: '#F7F3DA',
              borderRadius: 10,
              paddingLeft: 10,
              paddingRight: 10,
              padding: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: 12, marginLeft: 6, fontSize: 25 }}>
              ⓘ
            </div>
            <div>
              Удалив данные своей учетной записи из нашей системы, вы потеряете
              доступ к функциям наших цифровых продуктов, для которых требуется
              учетная запись.
            </div>
          </div>
          <div
            style={{ maxWidth: 'none', width: '100%', wordWrap: 'break-word' }}
          >
            <p>
              - Для удаления учетной записи через мобильное приложение, следует
              перейти в раздел "Личный кабинет". Для этого выполните следующие
              шаги: каснитесь значка личного кабинета в верхнем правом углу
              экрана. Затем выберите опцию "Удалить аккаунт", нажав на
              соответствующую кнопку.
            </p>
            <p>
              - Для удаления учетной записи, без использования мобильного
              приложения, вам следует отправить запрос на удаление на почту
              администратора, включающий необходимую информацию и контактные
              данные. Вот пример текста, который можно использовать в таком
              запросе:
            </p>
            <hr />
            <p>
              <strong>Тема: Запрос на удаление аккаунта</strong>
            </p>
            <p>Уважаемая поддержка/администрация [название сервиса],</p>
            <p>
              Я обращаюсь с просьбой о удалении моего аккаунта на вашем ресурсе.
              Пожалуйста, проведите соответствующие действия для полного
              удаления моей учетной записи.
            </p>
            <p>Информация об аккаунте:</p>
            <ul>
              <li>Имя пользователя: [ваше имя пользователя]</li>
              <li>Номер телефона: [ваш номер телефона]</li>
              <li>
                Электронная почта, связанная с аккаунтом: [ваш адрес электронной
                почты]
              </li>
            </ul>
            <p>
              Причина удаления: [укажите краткую причину, почему вы хотите
              удалить аккаунт]
            </p>
            <p>
              Прошу вас уведомить меня об успешном завершении процесса удаления
              аккаунта посредством ответного электронного письма или уведомления
              в системе.
            </p>
            <p>Благодарю за предоставление этой услуги.</p>
            <p>С уважением,[Ваше имя]</p>
            <hr />
            <p>
              Email для связи с администратором:{' '}
              <a href="mailto:info@sensei.ua">info@sensei.ua</a>
            </p>
            <br />
          </div>
          <p>
            Мы надеемся, что предоставленная здесь информация будет полезной для
            вас. Если у вас есть вопросы, не стесняйтесь обращаться к нам.
          </p>
        </div>
      </CardContent>
    </Card>
  );
};

export default DeleteAccountPage;
