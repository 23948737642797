import * as React from 'react';

import {
  BooleanField,
  EditButton,
  FunctionField,
  RecordContextProvider,
  ReferenceField,
  SelectField,
  useListContext,
  useTranslate,
} from 'react-admin';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';

import { IMoneyMovement } from '../../types';

const MobileGrid = () => {
  const translate = useTranslate();
  const { data, isLoading } = useListContext<IMoneyMovement>();

  if (isLoading || data.length === 0) {
    return null;
  }

  return (
    <Box margin="0.5em">
      {data.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
          <Card sx={{ margin: '0.5rem 0' }}>
            <CardHeader title={``} action={<EditButton />} />
            <CardContent sx={{ pt: 0 }}>
              <Typography variant="body2">
                {translate('resources.money_movement.fields.clientId')}
                :&nbsp;
                <ReferenceField
                  source="clientId"
                  label={translate('resources.money_movement.fields.clientId')}
                  reference="customers"
                >
                  <FunctionField
                    render={(record: IMoneyMovement) =>
                      record
                        ? `${record.name} ${
                            record.companyName && '(' + record.companyName + ')'
                          } - ${record.phone}`
                        : ''
                    }
                  />
                </ReferenceField>
                
              </Typography>
              <Typography variant="body2">
              {translate('resources.money_movement.fields.type')}
                :&nbsp;
                <SelectField
                  source="type"
                  label={translate('resources.money_movement.fields.type')}
                  choices={[
                    {
                      id: 'new_order',
                      name: translate(
                        'resources.money_movement.fields.types.new_order'
                      ),
                    },
                    {
                      id: 'delete_order',
                      name: translate(
                        'resources.money_movement.fields.types.delete_order'
                      ),
                    },
                    {
                      id: 'new_payment',
                      name: translate(
                        'resources.money_movement.fields.types.new_payment'
                      ),
                    },
                    {
                      id: 'change_credit',
                      name: translate(
                        'resources.money_movement.fields.types.change_credit'
                      ),
                    },
                  ]}
                />
              </Typography>
            </CardContent>
          </Card>
        </RecordContextProvider>
      ))}
    </Box>
  );
};

export default MobileGrid;
