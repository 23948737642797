import * as React from 'react';

import {
  ChipField,
  Datagrid,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
  useTranslate,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import MobileGrid from './MobileGrid';

const filters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    label="Раздел"
    source="section_q"
    reference="sections"
    allowEmpty
    perPage={1000}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    label="Категория"
    source="category_q"
    reference="categories"
    allowEmpty
    perPage={1000}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    label="Серия"
    source="serie_q"
    reference="series"
    allowEmpty
    perPage={1000}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

const ErrorCodesList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const translate = useTranslate();

  return (
    <List
      filters={!isSmall ? filters : undefined}
      bulkActionButtons={false}
      title={translate('pos.menu.errorcodes')}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <TextField
            source="name"
            label={translate('resources.error_codes.fields.name')}
          />
          <TextField
            source="description"
            label={translate('resources.error_codes.fields.description')}
          />
          <ReferenceField
            source="serie"
            label={translate('resources.error_codes.fields.serie')}
            reference="series"
          >
            <ChipField source="name" />
          </ReferenceField>
          <ReferenceField
            source="category"
            label={translate('resources.error_codes.fields.category')}
            reference="categories"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="section"
            label={translate('resources.error_codes.fields.section')}
            reference="sections"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField
            source="order"
            label={translate('resources.error_codes.fields.order')}
          />
        </Datagrid>
      )}
    </List>
  );
};

export default ErrorCodesList;
