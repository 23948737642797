import * as React from 'react';

import { Title, useLocaleState, useTheme, useTranslate } from 'react-admin';
import { darkTheme, lightTheme } from '../layout/themes';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';

const Configuration = () => {
  const translate = useTranslate();
  const [locale, setLocale] = useLocaleState();
  const [theme, setTheme] = useTheme();
  return (
    <Card>
      <Title title={translate('pos.configuration')} />
      <CardContent>
        <Box sx={{ width: '10em', display: 'inline-block' }}>
          {translate('pos.theme.name')}
        </Box>
        <Button
          variant="contained"
          sx={{ margin: '1em' }}
          color={theme?.palette?.mode === 'light' ? 'primary' : 'secondary'}
          onClick={() => setTheme(lightTheme)}
        >
          {translate('pos.theme.light')}
        </Button>
        <Button
          variant="contained"
          sx={{ margin: '1em' }}
          color={theme?.palette?.mode === 'dark' ? 'primary' : 'secondary'}
          onClick={() => setTheme(darkTheme)}
        >
          {translate('pos.theme.dark')}
        </Button>
      </CardContent>
      <CardContent>
        <Box sx={{ width: '10em', display: 'inline-block' }}>
          {translate('pos.language')}
        </Box>
        <Button
          variant="contained"
          sx={{ margin: '1em' }}
          color={locale === 'en' ? 'primary' : 'secondary'}
          onClick={() => setLocale('en')}
        >
          en
        </Button>
        <Button
          variant="contained"
          sx={{ margin: '1em' }}
          color={locale === 'ru' ? 'primary' : 'secondary'}
          onClick={() => setLocale('ru')}
        >
          ru
        </Button>
        {/* <Button
          variant="contained"
          sx={{ margin: '1em' }}
          color={locale === 'ua' ? 'primary' : 'default'}
          onClick={() => setLocale('ua')}
        >
          ua
        </Button> */}
      </CardContent>
      <CardContent>
        <Box sx={{ width: '10em', display: 'inline-block' }}>
          {translate('pos.configure_users')}
        </Box>
        <Button
          variant="contained"
          sx={{ margin: '1em' }}
          startIcon={<AccountCircleIcon />}
          component={Link}
          to="/users"
        >
          {translate('pos.users_link')}
        </Button>
      </CardContent>
    </Card>
  );
};

export default Configuration;
