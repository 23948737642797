import * as React from 'react';

import {
  BooleanInput,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  maxValue,
  minValue,
  number,
  required,
  useTranslate,
} from 'react-admin';

import { InputAdornment } from '@mui/material';

const DiscountsCreate = () => {
  const translate = useTranslate();

  return (
    <Create>
      <SimpleForm>
        <TextInput
          label={translate('resources.discounts.fields.name')}
          source="name"
          validate={[required()]}
        />
        <NumberInput
          label="Скидка"
          source="discount"
          step={0.1}
          max={100}
          min={0}
          format={(v) => parseFloat(v)}
          parse={(v) => v.toString().split('.')[0]}
          defaultValue={0}
          validate={[required(), number(), minValue(0), maxValue(60)]}
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          style={{ width: 200 }}
        />
        <BooleanInput
          label="Разрешить онлайн оплату"
          source="allowPayments"
          fullWidth
        />
        <BooleanInput
          label="Разрешить регистрацию гарантий"
          source="allowWarranty"
          fullWidth
        />
        <BooleanInput
          label="Разрешить начисление бонусов"
          source="allowBonus"
          fullWidth
        />
        <BooleanInput
          label="Разрешить моментальный бонус"
          source="allowImmediateBonus"
          fullWidth
        />
        <NumberInput
          label="Начисление бонусов через"
          source="bonusPeriod"
          step={1}
          max={360}
          min={0}
          format={(v) => parseFloat(v)}
          parse={(v) => v.toString().split('.')[0]}
          defaultValue={14}
          validate={[required(), number(), minValue(0), maxValue(60)]}
          InputProps={{
            endAdornment: <InputAdornment position="end">дней</InputAdornment>,
          }}
          style={{ width: 200 }}
        />
        <NumberInput
          step={0.1}
          max={5}
          min={0}
          label="Бонус за заказ"
          source="bonusesAmount"
          format={(v) => parseFloat(v)}
          parse={(v) => v.toString().split('.')[0]}
          defaultValue={0}
          validate={[required(), number(), minValue(0), maxValue(5)]}
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          style={{ width: 200 }}
        />
      </SimpleForm>
    </Create>
  );
};

export default DiscountsCreate;
