import * as React from 'react';

import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import {
  DateField,
  EditButton,
  RecordContextProvider,
  TextField,
  useListContext,
  useTranslate,
} from 'react-admin';

import { ISection } from '../../../types';

const MobileGrid = () => {
  const translate = useTranslate();
  const { data, isLoading } = useListContext<ISection>();

  if (isLoading || data.length === 0) {
    return null;
  }

  return (
    <Box margin="0.5em">
      {data.map((record) => (
        <RecordContextProvider key={record.id} value={record}>
          <Card sx={{ margin: '0.5rem 0' }}>
            <CardHeader
              title={`${record.nameTranslated.ru}`}
              action={<EditButton />}
            />
            <CardContent sx={{ pt: 0 }}>
              <Typography variant="body2">
                {translate('resources.sections.fields.currency')}
                :&nbsp;
                <TextField
                  source="currency"
                  label={translate('resources.sections.fields.currency')}
                />
              </Typography>
            </CardContent>
          </Card>
        </RecordContextProvider>
      ))}
    </Box>
  );
};

export default MobileGrid;
