import * as React from 'react';

import { useTranslate } from 'react-admin';

const TranslatedTextField = ({ record, source }) => {
  const translate = useTranslate();
  return (
    <span className="MuiTypography-root MuiTypography-body2">
      {translate(`resources.commands.fields.${record[source]}`)}
    </span>
  );
};

export default TranslatedTextField;
