import PriceCreate from './PriceCreate';
import PriceEdit from './PriceEdit';
import PriceIcon from '@mui/icons-material/LocalOffer';
import PriceList from './PriceList';

export default {
  list: PriceList,
  edit: PriceEdit,
  create: PriceCreate,
  icon: PriceIcon,
};
