import PromocodesCreate from './PromocodesCreate';
import PromocodesEdit from './PromocodesEdit';
import PromocodesIcon from '@mui/icons-material/Assistant';
import PromocodesList from './PromocodesList';

export default {
  list: PromocodesList,
  edit: PromocodesEdit,
  create: PromocodesCreate,
  icon: PromocodesIcon,
};
