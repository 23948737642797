import * as React from 'react';

import {
  Create,
  FileField,
  FileInput,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';

const CatalogsPdfCreate = () => {
  const translate = useTranslate();

  return (
    <Create>
      <SimpleForm>
      <TextInput source="name" label={translate('resources.catalogs_pdf.fields.name')} validate={[required()]} />
      <FileInput
        source="image"
        label={translate('resources.catalogs_pdf.fields.image')}
        accept="application/pdf"
        validate={[required()]}
      >
        <FileField source="image" title="title" />
      </FileInput>
      <NumberInput
        source="order"
        label={translate('resources.catalogs_pdf.fields.order')}
        min={1}
        max={99}
        step={1}
        defaultValue={99}
        validate={[required()]}
        style={{ width: 160 }}
      />
    </SimpleForm>
    </Create>
  );
};

export default CatalogsPdfCreate;
