import SectionsCreate from './SectionsCreate';
import SectionsEdit from './SectionsEdit';
import SectionsIcon from '@mui/icons-material/TurnedIn';
import SectionsList from './SectionsList';

export default {
  list: SectionsList,
  edit: SectionsEdit,
  create: SectionsCreate,
  icon: SectionsIcon,
};
