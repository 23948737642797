import SeriesCreate from './SeriesCreate';
import SeriesEdit from './SeriesEdit';
import SeriesIcon from '@mui/icons-material/FormatListBulleted';
import SeriesList from './SeriesList';

export default {
  list: SeriesList,
  edit: SeriesEdit,
  create: SeriesCreate,
  icon: SeriesIcon,
};
