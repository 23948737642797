import * as React from 'react';

import {
  Datagrid,
  DateField,
  List,
  TextField,
  useTranslate,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import MobileGrid from './MobileGrid';

const CatalogsPdfList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const translate = useTranslate();

  return (
    <List bulkActionButtons={false} title={translate('pos.menu.catalogs_pdf')}>
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <TextField
            source="name"
            label={translate('resources.catalogs_pdf.fields.name')}
          />
          <DateField
            source="date"
            label={translate('resources.catalogs_pdf.fields.date')}
          />
          <TextField
            source="order"
            label={translate('resources.catalogs_pdf.fields.order')}
          />
        </Datagrid>
      )}
    </List>
  );
};

export default CatalogsPdfList;
