import * as React from 'react';

import {
  Edit,
  ImageField,
  ImageInput,
  Labeled,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { INotifications } from '../../types';

const Title = () => {
  const record = useRecordContext<INotifications>();
  return <span>Push уведомление {record ? `"${record.name}"` : ''}</span>;
};

const NotificationsEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<Title />}>
      <SimpleForm>
        <TextInput
          source="notificationTitle"
          label="Заголовок push увед."
          validate={[required()]}
          disabled
          inputProps={{
            maxLength: 30,
          }}
        />
        <TextInput
          source="notificationText"
          label="Подзаголовок push увед."
          validate={[required()]}
          disabled
          inputProps={{
            maxLength: 40,
          }}
        />
        <Labeled label="Текущее фото">
          <ImageField source="image" />
        </Labeled>
        <ImageInput
          source="image"
          label="Новое Фото"
          accept="image/*"
          multiple={false}
        >
          <ImageField source="image" />
        </ImageInput>
        <TextInput source="name" label="Заголовок" validate={[required()]} />
        <TextInput
          source="text"
          label="Текст"
          validate={[required()]}
          multiline
          fullWidth
        />
        {/* <RichTextInput source="body" label="Содержимое" validate={[required()]} /> */}
        <ReferenceInput
          label="Получатель"
          source="sendTo"
          reference="customers"
          perPage={1000}
        >
          <SelectInput
            optionText={(customer) =>
              `${customer.name} ${customer?.companyName ?? ''} (${
                customer.phone
              })`
            }
            resettable
            disabled={true}
          />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default NotificationsEdit;
