import { useGetList, useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';
import { IExchangeRate } from '../types';
import React from 'react';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const ExchangeRate = () => {
  const translate = useTranslate();

  const { isLoading, data: rate } = useGetList<IExchangeRate>('rates', {
    sort: { field: 'id', order: 'ASC' },
    pagination: { page: 1, perPage: 50 },
  });

  if (isLoading || !rate) return null;

  return (
    <CardWithIcon
      to="/rates"
      icon={TrendingUpIcon}
      title={translate('pos.dashboard.exchange_rate')}
      subtitle={`${rate[0].name} ${rate[0].cashRate} / ${rate[0].cashlessRate}`}
    />
  );
};

export default ExchangeRate;
