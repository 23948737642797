import SettingsCreate from './SettingsCreate';
import SettingsEdit from './SettingsEdit';
import SettingsIcon from '@mui/icons-material/SettingsApplications';
import SettingsList from './SettingsList';

export default {
  list: SettingsList,
  edit: SettingsEdit,
  create: SettingsCreate,
  icon: SettingsIcon,
};
