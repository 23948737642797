import * as React from 'react';

import { ReferenceField, ReferenceFieldProps, TextField } from 'react-admin';

const DisscountReferenceField = (
  props: Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
    source?: string;
  }
) => (
  <ReferenceField
    label="Категория цен"
    source="discount"
    reference="discounts"
    {...props}
  >
    <TextField source="name" />
  </ReferenceField>
);

DisscountReferenceField.defaultProps = {
  source: 'discount',
  addLabel: true,
};

export default DisscountReferenceField;
