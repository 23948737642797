import { fetchUtils } from 'ra-core';
import { stringify } from 'query-string';

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-serverdata
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const options = {
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        'x-user-id': userId,
      }),
    };

    return httpClient(url, options).then(({ headers, json }) => {
      if (!headers.has('x-total-count')) {
        throw new Error(
          'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
        );
      }
      return {
        data: json.map((resource) => ({ ...resource, id: resource._id })),
        total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
      };
    });
  },

  getOne: (resource, params) => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const options = {
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        'x-user-id': userId,
      }),
    };
    return httpClient(`${apiUrl}/${resource}/${params.id}`, options).then(
      ({ json }) => ({
        data: { ...json, id: json._id },
      })
    );
  },

  getMany: (resource, params) => {
    const query = {
      id: params.ids,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const options = {
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        'x-user-id': userId,
      }),
    };
    return httpClient(url, options).then(({ json }) => ({
      data: json.map((resource) => ({ ...resource, id: resource._id })),
    }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      [params.target]: params.id,
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const options = {
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        'x-user-id': userId,
      }),
    };

    return httpClient(url, options).then(({ headers, json }) => {
      if (!headers.has('x-total-count')) {
        throw new Error(
          'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
        );
      }
      return {
        data: json.map((resource) => ({ ...resource, id: resource._id })),
        total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
      };
    });
  },

  update: (resource, params) => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    if (resource === 'rates') {
      params.data.date = new Date();
    }
    if (!params.data.image) {
      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
        headers: new Headers({
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
          'x-user-id': userId,
        }),
      }).then(({ json }) => ({ data: { ...json, id: json._id } }));
    }
    const formData = new FormData();
    const object = params.data;
    formData.append('image', params.data.image.rawFile);
    for (const key in object) {
      if (object.hasOwnProperty(key) && key.includes('itemImage_')) {
        formData.append(key, object[key].rawFile);
      } else if (object.hasOwnProperty(key) && key !== 'image') {
        Object.isO;
        const value =
          Array.isArray(object[key]) || typeof object[key] === 'object'
            ? JSON.stringify(object[key])
            : object[key];
        formData.append(key, value);
      }
    }
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: formData,
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        'x-user-id': userId,
      }),
    }).then(({ json }) => ({ data: { ...json, id: json._id } }));
  },

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource, params) => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    return Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
          headers: new Headers({
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
            'x-user-id': userId,
          }),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) }));
  },
  create: (resource, params) => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    if (!params.data.image) {
      return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
        headers: new Headers({
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
          'x-user-id': userId,
        }),
      }).then(({ json }) => ({
        data: { ...params.data, id: json._id },
      }));
    }
    const formData = new FormData();
    const object = params.data;
    formData.append('image', object.image.rawFile);
    for (const key in object) {
      if (
        object.hasOwnProperty(key) &&
        object[key] !== undefined &&
        key.includes('itemImage_')
      ) {
        formData.append(key, object[key].rawFile);
      } else if (object.hasOwnProperty(key) && key !== 'image') {
        const value =
          typeof object[key] === 'object' || Array.isArray(object[key])
            ? JSON.stringify(object[key])
            : object[key];
        formData.append(key, value);
      }
    }
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: formData,
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        'x-user-id': userId,
      }),
    }).then(({ json }) => ({
      data: { ...params.data, id: json._id },
    }));
  },

  delete: (resource, params) => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        'x-user-id': userId,
      }),
    }).then(({ json }) => ({ data: { ...json, id: json._id } }));
  },

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    return Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
          headers: new Headers({
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
            'x-user-id': userId,
          }),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) }));
  },
});
