// import RetesCreate from './RatesCreate';
import RetesEdit from './RatesEdit';
import RetesIcon from '@mui/icons-material/MonetizationOn';
import RetesList from './RatesList';

export default {
  list: RetesList,
  edit: RetesEdit,
  // create: RetesCreate,
  icon: RetesIcon,
};
