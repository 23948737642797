import * as React from 'react';

import {
  Create,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';

import { MyTranslatableInputs } from '../../../components/MyTranslatableInputs';

const CategoriesCreate = () => {
  const translate = useTranslate();

  return (
    <Create>
      <SimpleForm>
        <ReferenceInput
          source="section"
          label={translate('resources.categories.fields.section')}
          reference="sections"
          validate={[required()]}
          perPage={1000}
        >
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
        {/* <TextInput
          source="name"
          label={translate('resources.categories.fields.name')}
          validate={[required()]}
        /> */}
        <MyTranslatableInputs groupKey="name">
          <TextInput
            source="nameTranslated"
            label={translate('resources.categories.fields.name')}
            validate={[required()]}
          />
        </MyTranslatableInputs>
        <NumberInput
          source="order"
          label={translate('resources.categories.fields.order')}
          min={1}
          max={99}
          step={1}
          defaultValue={99}
          validate={[required()]}
          style={{ width: 160 }}
        />
      </SimpleForm>
    </Create>
  );
};

export default CategoriesCreate;
