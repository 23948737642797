import * as React from 'react';

import {
  BooleanInput,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  Labeled,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { Divider } from '@mui/material';
import { IPaymentReport } from '../../types';

const Title = () => {
  const record = useRecordContext<IPaymentReport>();
  const translate = useTranslate();
  return (
    <span>
      {translate('resources.payment_reports.name')}{' '}
      {record ? `"${record._id}"` : ''}
    </span>
  );
};

// const EditToolbar = () => (
//   <Toolbar>
//     <SaveButton />
//   </Toolbar>
// );

const PaymentReportsEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<Title />}>
      <SimpleForm>
        <DateTimeInput
          source="paymentDate"
          label={translate('resources.payment_reports.fields.paymentDate')}
          validate={[required()]}
        />
        <ReferenceInput
          source="clientId"
          reference="customers"
          allowEmpty
          perPage={1000}
        >
          <SelectInput
            disabled
            label={translate('resources.payment_reports.fields.clientId')}
            optionText={(record) =>
              record
                ? `${record.name} ${
                    record.companyName && '(' + record.companyName + ')'
                  } - ${record.phone}`
                : ''
            }
            fullWidth
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) =>
            formData.orderId?.length ? (
              <Labeled
                label={translate('resources.payment_reports.fields.orderId')}
              >
                <ReferenceField source="orderId" reference="orders">
                  <TextField
                    source="orderId"
                    label={translate(
                      'resources.payment_reports.fields.orderId'
                    )}
                  />
                </ReferenceField>
              </Labeled>
            ) : null
          }
        </FormDataConsumer>

        <SelectInput
          source="paymentMethod"
          label={translate('resources.payment_reports.fields.paymentMethod')}
          validate={[required()]}
          choices={[
            {
              id: 'fop',
              name: translate('resources.payment_reports.fields.payment.fop'),
            },
            {
              id: 'payment_account',
              name: translate(
                'resources.payment_reports.fields.payment.payment_account'
              ),
            },
            // {
            //   id: 'mobile_app',
            //   name: translate(
            //     'resources.payment_reports.fields.payment.mobile_app'
            //   ),
            // },
          ]}
        />
        <Labeled
          label={translate('resources.payment_reports.fields.paymentValue')}
        >
          <NumberField
            source="paymentValue"
            options={{
              style: 'currency',
              currency: 'UAH',
            }}
          />
        </Labeled>
        <Labeled
          label={translate(
            'resources.payment_reports.fields.paymentValueInUSD'
          )}
        >
          <NumberField
            source="paymentValueInUSD"
            options={{
              style: 'currency',
              currency: 'USD',
            }}
          />
        </Labeled>
        <Divider />
        {/* <FormDataConsumer>
          {({ formData }) =>
            formData.orderId?.length ? (
              <BooleanInput
                source="processed"
                label={translate(
                  'resources.payment_reports.fields.processed_alt'
                )}
                // disabled={formData.processed}
                fullWidth
              />
            ) : null
          }
        </FormDataConsumer> */}
        <BooleanInput
          source="processed"
          label={translate('resources.payment_reports.fields.processed')}
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

export default PaymentReportsEdit;
