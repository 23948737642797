// import ContactsCreate from './ContactsCreate';
import ContactsEdit from './ContactsEdit';
import ContactsIcon from '@mui/icons-material/Contacts';
import ContactsList from './ContactsList';

export default {
  list: ContactsList,
  edit: ContactsEdit,
  // create: ContactsCreate,
  icon: ContactsIcon,
};
