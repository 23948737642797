import * as React from 'react';

import {
  BooleanInput,
  Create,
  FileField,
  FileInput,
  SimpleForm,
  TextInput,
  maxLength,
  required,
  useTranslate,
} from 'react-admin';

import { Typography } from '@mui/material';

const PriceCreate = () => {
  const translate = useTranslate();

  return (
    <Create>
      <SimpleForm>
        <TextInput
          source="name"
          label={translate('resources.price.fields.name')}
          validate={[required()]}
        />
        <Typography component="span" variant="body2">
          {translate('resources.price.fields.theme')}
        </Typography>
        <TextInput
          label={translate('resources.price.fields.topic')}
          source="topic"
          resettable
          validate={[required()]}
        />
        <TextInput
          label={translate('resources.price.fields.description')}
          source="description"
          multiline
          resettable
          fullWidth
          validate={[required(), maxLength(1500)]}
        />
        <FileInput
          source="image"
          label={translate('resources.price.fields.image')}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          validate={[required()]}
        >
          <FileField source="image" title="title" />
        </FileInput>
        {/* <SelectInput
        label="День недели"
        source="day"
        choices={[
          { id: 'monday', name: 'Понедельник' },
          { id: 'tuesday', name: 'Вторник' },
          { id: 'wensday', name: 'Среда' },
          { id: 'thursday', name: 'Четверг' },
          { id: 'friday', name: 'Пятница' },
          { id: 'saturday', name: 'Суббота' },
        ]}
        validate={[required()]}
      /> */}
        <BooleanInput
          fullWidth
          label={translate('resources.price.fields.active')}
          source="active"
          defaultValue={true}
        />
      </SimpleForm>
    </Create>
  );
};

export default PriceCreate;
