import * as React from 'react';

import {
  Edit,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { IErrorCode } from '../../types';

const Title = () => {
  const record = useRecordContext<IErrorCode>();
  return <span>Ошибка {record ? `"${record.name}"` : ''}</span>;
};

const ErrorCodesEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<Title />}>
      <SimpleForm>
        <ReferenceInput
          source="section"
          reference="sections"
          validate={[required()]}
          perPage={1000}
        >
          <SelectInput
            optionText="name"
            label={translate('resources.error_codes.fields.section')}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.section ? (
              <ReferenceInput
                source="category"
                reference="categories"
                perPage={1000}
                filter={{ section_q: formData.section }}
                validate={[required()]}
                {...rest}
              >
                <SelectInput
                  optionText="name"
                  label={translate('resources.error_codes.fields.category')}
                />
              </ReferenceInput>
            ) : null
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.category ? (
              <ReferenceInput
                source="serie"
                reference="series"
                perPage={1000}
                filter={{ category_q: formData.category }}
                validate={[required()]}
                {...rest}
              >
                <SelectInput
                  optionText="name"
                  label={translate('resources.error_codes.fields.serie')}
                />
              </ReferenceInput>
            ) : null
          }
        </FormDataConsumer>
        <TextInput
          source="name"
          label={translate('resources.error_codes.fields.name')}
        />
        <TextInput
          source="description"
          label={translate('resources.error_codes.fields.description')}
          multiline
          fullWidth
        />
        <NumberInput
          source="order"
          label={translate('resources.error_codes.fields.order')}
          min={1}
          max={99}
          step={1}
          validate={[required()]}
          style={{ width: 160 }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ErrorCodesEdit;
