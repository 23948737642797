// import UsersCreate from './UsersCreate';
import UsersEdit from './UsersEdit';
import UsersIcon from '@mui/icons-material/Group';
import UsersList from './UsersList';

export default {
  list: UsersList,
  edit: UsersEdit,
  // create: UsersCreate,
  icon: UsersIcon,
};
