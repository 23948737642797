import * as React from 'react';

import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';

const SettingsCreate = () => {
  const translate = useTranslate();

  return (
    <Create>
      <SimpleForm>
        <TextInput
          source="name"
          label={translate('resources.settings.fields.name')}
          validate={[required()]}
        />
        <BooleanInput
          source="maintenance"
          label={translate('resources.settings.fields.maintenance')}
        />
        <BooleanInput
          source="usePayments"
          label={translate('resources.settings.fields.usePayments')}
        />
        <BooleanInput
          source="useBonuses"
          label={translate('resources.settings.fields.useBonuses')}
        />
        <BooleanInput
          source="useWarranty"
          label={translate('resources.settings.fields.useWarranty')}
        />
        <BooleanInput
          source="promocodeReminder"
          label={translate('resources.settings.fields.promocodeReminder')}
        />
        <TextInput
          source="novaPoshtaKey"
          label={translate('resources.settings.fields.novaPoshtaKey')}
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};

export default SettingsCreate;
