import CategoriesCreate from './CategoriesCreate';
import CategoriesEdit from './CategoriesEdit';
import CategoriesIcon from '@mui/icons-material/CalendarViewDay';
import CategoriesList from './CategoriesList';

export default {
  list: CategoriesList,
  edit: CategoriesEdit,
  create: CategoriesCreate,
  icon: CategoriesIcon,
};
