const specificationsCaptions = [
  { id: 'main', name: 'Основное' },
  { id: 'dimensions_inner', name: 'Размеры внутреннего блока' },
  { id: 'dimensions_outer', name: 'Размеры внешнего блока' },
  { id: 'dimensions_box', name: 'Размеры упаковки' },
  { id: 'cooling', name: 'Охлаждение' },
  { id: 'heating', name: 'Обогрев' },
  { id: 'pressure', name: 'Давление' },
  { id: 'room_temperature', name: 'Температура помещения' },
  { id: 'compressor', name: 'Компрессор' },
  { id: 'refrigerant_type', name: 'Тип хладагента / Количество' },
  { id: 'pipe_diameter', name: 'Диаметр труб' },
  { id: 'temperature_range', name: 'Диапазон рабочих температур' },
  { id: 'performance', name: 'Производительность' },
  { id: 'energy_efficiency', name: 'Уровень энергоэффективности' },
  { id: 'power_consumption', name: 'Потребляемая мощность' },
  { id: 'consumption_current', name: 'Потребляетый ток' },
  { id: 'pipes', name: 'Трубы' },
];

export default specificationsCaptions;