import * as React from 'react';

import {
  BooleanInput,
  Edit,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { ISection } from '../../../types';
import { MyTranslatableInputs } from '../../../components/MyTranslatableInputs';

const Title = () => {
  const record = useRecordContext<ISection>();
  const translate = useTranslate();
  return (
    <span>
      {translate('resources.sections.editText')}{' '}
      {record ? `"${record.name}"` : ''}
    </span>
  );
};

const SectionsEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<Title />}>
      <SimpleForm>
        {/* <TextInput
          source="name"
          label={translate('resources.sections.fields.name')}
          validate={[required()]}
        /> */}
        <MyTranslatableInputs groupKey="name">
          <TextInput
            source="nameTranslated"
            label={translate('resources.sections.fields.name')}
            validate={[required()]}
          />
        </MyTranslatableInputs>
        <SelectInput
          source="currency"
          defaultValue="$"
          label={translate('resources.sections.fields.currency')}
          validate={[required()]}
          choices={[
            { id: '$', name: '$' },
            // { id: '€', name: '€' },
            { id: 'грн', name: 'грн' },
          ]}
        />
        <BooleanInput
          source="showErrorCodes"
          label={translate('resources.sections.fields.showErrorCodes')}
          fullWidth
        />
        <NumberInput
          source="order"
          label={translate('resources.sections.fields.order')}
          min={1}
          max={99}
          step={1}
          validate={[required()]}
          style={{ width: 160 }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default SectionsEdit;
