import * as React from 'react';

import {
  Create,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';

import { Divider } from '@mui/material';

const NotificationsCreate = () => {
  const translate = useTranslate();

  return (
    <Create>
      <SimpleForm>
        <TextInput
          source="notificationTitle"
          label="Заголовок push увед."
          validate={[required()]}
          inputProps={{
            maxLength: 30,
          }}
        />
        <TextInput
          source="notificationText"
          label="Подзаголовок push увед."
          validate={[required()]}
          inputProps={{
            maxLength: 40,
          }}
        />
        <ImageInput
          source="image"
          label="Фото"
          accept="image/*"
          multiple={false}
          validate={[required()]}
        >
          <ImageField source="image" />
        </ImageInput>
        <TextInput source="name" label="Заголовок" validate={[required()]} />
        <TextInput
          source="text"
          label="Текст"
          validate={[required()]}
          multiline
          fullWidth
        />
        {/* <RichTextInput source="body" label="Содержимое" validate={[required()]} /> */}
        <Divider />
        <p>Оставьте это поле пустым для отправки всем пользователям</p>
        <ReferenceInput
          label="Получатель"
          source="sendTo"
          reference="customers"
          perPage={1000}
        >
          <SelectInput
            optionText={(customer) =>
              `${customer.name} ${customer?.companyName ?? ''} (${
                customer.phone
              })`
            }
            resettable
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default NotificationsCreate;
