import * as React from 'react';

import {
  Datagrid,
  EmailField,
  List,
  TextField,
  useTranslate,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import MobileGrid from './MobileGrid';

const ContactsList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const translate = useTranslate();

  return (
    <List bulkActionButtons={false} title={translate('pos.menu.contacts')}>
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <TextField
            source="phoneNumber"
            label={translate('resources.contacts.fields.phoneNumber')}
          />
          <TextField
            source="office.label"
            label={translate('resources.contacts.fields.office.label')}
          />
          <EmailField
            source="email"
            label={translate('resources.contacts.fields.email')}
          />
        </Datagrid>
      )}
    </List>
  );
};

export default ContactsList;
