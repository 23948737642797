import * as React from 'react';

import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  SearchInput,
  TextField,
  useTranslate,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import MobileGrid from './MobileGrid';

const filters = [<SearchInput source="q" alwaysOn />];

const RecurringNotificationsList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const translate = useTranslate();

  return (
    <List
      filters={!isSmall ? filters : undefined}
      bulkActionButtons={false}
      title={translate('pos.menu.recurring_notifications')}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <TextField
            source="name"
            label={translate('resources.recurring_notifications.fields.name')}
          />
          <DateField
            source="date"
            label={translate('resources.recurring_notifications.fields.date')}
            showTime
          />
          <BooleanField
            source="active"
            label={translate('resources.recurring_notifications.fields.active')}
          />
        </Datagrid>
      )}
    </List>
  );
};

export default RecurringNotificationsList;
