import CatalogsPdfCreate from './CatalogsPdfCreate';
import CatalogsPdfEdit from './CatalogsPdfEdit';
import CatalogsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CatalogsPdfList from './CatalogsPdfList';

export default {
  list: CatalogsPdfList,
  edit: CatalogsPdfEdit,
  create: CatalogsPdfCreate,
  icon: CatalogsPdfIcon,
};
