import './App.css';

import * as React from 'react';

import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Layout, Login } from './layout';

import Configuration from './configuration/Configuration';
import { Dashboard } from './dashboard/index';
import DeleteAccountPage from './pages/DeleteAccount';
import { Route } from 'react-router';
import authProvider from './authProvider';
import catalogsPdf from './resources/catalogsPdf';
import categories from './resources/catalog/categories';
import contacts from './resources/contacts';
import customers from './resources/customers';
import discounts from './resources/discounts';
import errorcodes from './resources/errorCodes';
import items from './resources/catalog/items';
import jsonServerProvider from './dataProvider';
import { lightTheme } from './layout/themes';
import moneyMovement from './resources/moneyMovement';
import notifications from './resources/notifications';
import orders from './resources/orders/index';
import paymentReports from './resources/paymentReports';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import price from './resources/price';
import promocodes from './resources/promocodes';
import rates from './resources/rates';
import recurringNotifications from './resources/recurringNotifications';
import russianMessages from './i18n/ru';
import sections from './resources/catalog/sections';
import series from './resources/catalog/series';
import settings from './resources/settings';
import users from './resources/users';
import warranties from './resources/warranties';

const dataProvider = jsonServerProvider('/api');

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === 'en') {
    return import('./i18n/en').then((messages) => messages.default);
  }
  if (locale === 'ua') {
    return import('./i18n/ua').then((messages) => messages.default);
  }

  return russianMessages;
}, 'ru');

const App = () => {
  return (
    <Admin
      title="Панель управления"
      dataProvider={dataProvider}
      authProvider={authProvider}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
      disableTelemetry
      theme={lightTheme}
    >
      <CustomRoutes>
        <Route path="/configuration" element={<Configuration />} />
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route path="/delete-account" element={<DeleteAccountPage />} />
      </CustomRoutes>
      <Resource name="customers" {...customers} />
      <Resource
        name="orders"
        {...orders}
        options={{ label: 'pos.menu.orders' }}
      />
      <Resource name="users" {...users} />
      <Resource name="sections" {...sections} />
      <Resource name="categories" {...categories} />
      <Resource name="series" {...series} />
      <Resource name="items" {...items} />
      <Resource name="rates" {...rates} />
      <Resource name="discounts" {...discounts} />
      <Resource name="errorcodes" {...errorcodes} />
      <Resource name="catalogs-pdf" {...catalogsPdf} />
      <Resource name="notifications" {...notifications} />
      <Resource name="contacts" {...contacts} />
      <Resource name="price" {...price} />
      <Resource name="settings" {...settings} />
      <Resource name="payment-report" {...paymentReports} />
      <Resource name="recurring-notifications" {...recurringNotifications} />
      <Resource name="money-movement" {...moneyMovement} />
      <Resource name="warranties" {...warranties} />
      <Resource name="promocodes" {...promocodes} />
    </Admin>
  );
};

export default App;
