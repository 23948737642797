import CustomerEdit from './CustomerEdit';
import CustomerIcon from '@mui/icons-material/People';
import CustomerList from './CustomerList';

const resource = {
  list: CustomerList,
  edit: CustomerEdit,
  icon: CustomerIcon,
};

export default resource;