import * as React from 'react';

import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectField,
  useTranslate,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import { IMoneyMovement } from '../../types';
import MobileGrid from './MobileGrid';

const filters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    label="Клиент"
    source="client_id"
    reference="customers"
    allowEmpty
    perPage={10000}
  >
    <AutocompleteInput
      label="Клиент"
      optionText={(record) =>
        record
          ? `${record.name} ${
              record.companyName && '(' + record.companyName + ')'
            } - ${record.phone}`
          : ''
      }
    />
  </ReferenceInput>,
];

const MoneyMovementList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const translate = useTranslate();

  return (
    <List
      filters={!isSmall ? filters : undefined}
      title={translate('pos.menu.money_movement')}
      perPage={25}
      sort={{ field: 'date', order: 'DESC' }}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <ReferenceField
            source="clientId"
            label={translate('resources.money_movement.fields.clientId')}
            reference="customers"
          >
            <FunctionField
              render={(record: IMoneyMovement) =>
                record
                  ? `${record.name} ${
                      record.companyName && '(' + record.companyName + ')'
                    } - ${record.phone}`
                  : ''
              }
            />
          </ReferenceField>
          {/* <ReferenceField
            source="orderId"
            label={translate('resources.money_movement.fields.orderId')}
            reference="orders"
          >
            <TextField source="orderId" />
          </ReferenceField> */}
          <SelectField
            source="type"
            label={translate('resources.money_movement.fields.type')}
            choices={[
              {
                id: 'new_order',
                name: translate(
                  'resources.money_movement.fields.types.new_order'
                ),
              },
              {
                id: 'delete_order',
                name: translate(
                  'resources.money_movement.fields.types.delete_order'
                ),
              },
              {
                id: 'new_payment',
                name: translate(
                  'resources.money_movement.fields.types.new_payment'
                ),
              },
              {
                id: 'change_credit',
                name: translate(
                  'resources.money_movement.fields.types.change_credit'
                ),
              },
            ]}
          />
          <NumberField
            source="oldSumm"
            label={translate('resources.money_movement.fields.oldSumm')}
            options={{
              style: 'currency',
              currency: 'USD',
            }}
          />
          <NumberField
            source="value"
            label={translate('resources.money_movement.fields.value')}
            options={{
              style: 'currency',
              currency: 'USD',
            }}
          />
          <NumberField
            source="newSumm"
            label={translate('resources.money_movement.fields.newSumm')}
            options={{
              style: 'currency',
              currency: 'USD',
            }}
          />
          <DateField
            source="date"
            label={translate('resources.money_movement.fields.date')}
            showTime
          />
        </Datagrid>
      )}
    </List>
  );
};

export default MoneyMovementList;
