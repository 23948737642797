import * as React from 'react';

import {
  ChipField,
  Datagrid,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
  useTranslate,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import MobileGrid from './MobileGrid';

// const ListActions = (props) => {
//   const {
//     className,
//     basePath,
//     total,
//     resource,
//     currentSort,
//     filterValues,
//     exporter,
//     filters,
//     displayedFilters,
//     showFilter,
//   } = props;
//   const config = {
//     logging: true,
//     validateRow: async (row) => {
//       if (row.id) {
//         // throw new Error("AAAA");
//       }
//     },
//     postCommitCallback: (reportItems) => {
//       console.log('reportItems', reportItems);
//     },
//     transformRows: (csvRows) => {
//       console.log('csvRows', csvRows);
//       let n = JSON.stringify(csvRows[0]);
//       n = n.replace(/;/g, ',');
//       console.log('n', JSON.parse(n));
//     },
//     // disableImportNew: true,
//     // disableImportOverwrite: true,
//   };
//   return (
//     <TopToolbar className={className}>
//       {filters &&
//         cloneElement(filters, {
//           resource,
//           showFilter,
//           displayedFilters,
//           filterValues,
//           context: 'button',
//         })}
//       <CreateButton basePath={basePath} />
//       <ExportButton
//         disabled={total === 0}
//         resource={resource}
//         sort={currentSort}
//         filter={filterValues}
//         exporter={exporter}
//       />
//       <ImportButton
//         {...props}
//         label="Импорт"
//         {...config}
//         parseConfig={{ dynamicTyping: true }}
//       />
//     </TopToolbar>
//   );
// };

const filters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    label="Раздел"
    source="section_q"
    reference="sections"
    allowEmpty
    perPage={1000}
  >
    <SelectInput optionText="name" label="Раздел" />
  </ReferenceInput>,
  <ReferenceInput
    label="Категория"
    source="category_q"
    reference="categories"
    allowEmpty
    perPage={1000}
  >
    <SelectInput optionText="name" label="Категория" />
  </ReferenceInput>,
  <ReferenceInput
    label="Серия"
    source="serie_q"
    reference="series"
    allowEmpty
    perPage={1000}
  >
    <SelectInput optionText="name" label="Серия" />
  </ReferenceInput>,
];

const ItemsList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const translate = useTranslate();

  return (
    <List
      bulkActionButtons={false}
      title={translate('pos.menu.items')}
      filters={!isSmall ? filters : undefined}
      // actions={<ListActions />}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="name" label="Название товара" />
          <ReferenceField label="Серия" source="serie" reference="series">
            <ChipField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Категория"
            source="category"
            reference="categories"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Раздел" source="section" reference="sections">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="price" label="Цена, розн." />
          <TextField source="order" label="Порядок" />
          <TextField source="stock1" label="Остаток" />
        </Datagrid>
      )}
    </List>
  );
};

export default ItemsList;
