import * as React from 'react';

import {
  AutocompleteInput,
  BooleanInput,
  Create,
  DateTimeInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';

import { Divider } from '@mui/material';
import { format } from 'date-fns';

const PaymentReportsCreate = () => {
  const translate = useTranslate();

  return (
    <Create>
      <SimpleForm>
        <DateTimeInput
          source="paymentDate"
          label={translate('resources.payment_reports.fields.paymentDate')}
          validate={[required()]}
        />
        <ReferenceInput
          source="clientId"
          reference="customers"
          // validate={[required()]}
          perPage={10000}
        >
          <AutocompleteInput
            label={translate('resources.payment_reports.fields.clientId')}
            optionText={(record) =>
              record
                ? `${record.name} ${
                    record.companyName && '(' + record.companyName + ')'
                  } - ${record.phone}`
                : ''
            }
            fullWidth
            validate={[required()]}
          />
        </ReferenceInput>
        <BooleanInput
          label={translate('resources.payment_reports.fields.applyToOrder')}
          source="applyToOrder"
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.clientId && formData.applyToOrder ? (
              <ReferenceInput
                source="orderId"
                reference="orders"
                perPage={1000}
                filter={{ client_id: formData.clientId, payment: false }}
                validate={[required()]}
                {...rest}
              >
                <SelectInput
                  label={translate('resources.payment_reports.fields.orderId')}
                  optionText={(record) =>
                    record
                      ? `#${record.orderId} от ${format(
                          new Date(record.date),
                          'MM.dd.yyyy HH:mm'
                        )} - ${record.totalSummInUSD.toFixed(2)}$`
                      : ''
                  }
                  fullWidth
                />
              </ReferenceInput>
            ) : null
          }
        </FormDataConsumer>
        <SelectInput
          source="paymentMethod"
          label={translate('resources.payment_reports.fields.paymentMethod')}
          validate={[required()]}
          choices={[
            { id: 'fop', name: 'Оплата на карту' },
            { id: 'payment_account', name: 'На расчетный счет' },
          ]}
        />
        <NumberInput
          source="paymentValue"
          label={translate('resources.payment_reports.fields.paymentValue')}
          min={1}
          max={1000000}
          step={0.01}
          format={(v) => parseFloat(v)}
          parse={(v) => v.toString()}
          validate={[required()]}
        />
        <Divider />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.clientId && formData.applyToOrder ? (
              <BooleanInput
                source="processed"
                label={translate(
                  'resources.payment_reports.fields.processed_alt'
                )}
                fullWidth
                defaultValue={false}
              />
            ) : null
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default PaymentReportsCreate;
