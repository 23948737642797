import * as React from 'react';

import {
  Datagrid,
  DateField,
  List,
  SearchInput,
  TextField,
  useTranslate,
} from 'react-admin';
import { Theme, useMediaQuery } from '@mui/material';

import MobileGrid from './MobileGrid';

const filters = [<SearchInput source="q" alwaysOn />];

const RateList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const translate = useTranslate();

  return (
    <List
      filters={!isSmall ? filters : undefined}
      bulkActionButtons={false}
      title={translate('pos.menu.rates')}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="name" label="Валюта" />
          <TextField source="cashRate" label="Нал" />
          <TextField source="cashlessRate" label="Безнал" />
          <DateField source="date" label="Дата изменения" showTime />
        </Datagrid>
      )}
    </List>
  );
};

export default RateList;
