import * as React from 'react';

import { useGetList, useTranslate } from 'react-admin';

import AssignmentTurnedIIcon from '@mui/icons-material/AssignmentTurnedIn';
import CardWithIcon from './CardWithIcon';
import { Item } from '../types';

const Items = () => {
  const translate = useTranslate();

  const { isLoading, total: totalCond } = useGetList<Item>('items', {
    sort: { field: 'id', order: 'ASC' },
    pagination: { page: 1, perPage: 50 },
  });

  if (isLoading || !totalCond) return null;

  return (
    <CardWithIcon
      to="/items"
      icon={AssignmentTurnedIIcon}
      title={translate('pos.dashboard.items_count')}
      subtitle={totalCond}
    />
  );
};

export default Items;
