import ItemsCreate from './ItemsCreate';
import ItemsEdit from './ItemsEdit';
import ItemsIcon from '@mui/icons-material/AssignmentTurnedIn';
import ItemsList from './ItemsList';

export default {
  list: ItemsList,
  edit: ItemsEdit,
  create: ItemsCreate,
  icon: ItemsIcon,
};
