import * as React from 'react';

import { ReferenceField, ReferenceFieldProps } from 'react-admin';

import FullNameField from './FullNameField';

const CustomerReferenceField = (
  props: Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
    source?: string;
  }
) => (
  <ReferenceField source="clientId" reference="customers" {...props}>
    <FullNameField />
  </ReferenceField>
);

CustomerReferenceField.defaultProps = {
  source: 'clientId',
  addLabel: true,
};

export default CustomerReferenceField;
