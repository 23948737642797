// import MoneyMovementCreate from './MoneyMovementCreate';
// import MoneyMovementEdit from './MoneyMovementEdit';
import MoneyMovementIcon from '@mui/icons-material/Article';
import MoneyMovementList from './MoneyMovementList';

export default {
  list: MoneyMovementList,
  icon: MoneyMovementIcon,
  // edit: MoneyMovementEdit,
  // create: MoneyMovementCreate,
};
