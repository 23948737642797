// import RecurringNotificationsCreate from './RecurringNotificationsCreate';
import RecurringNotificationsEdit from './RecurringNotificationsEdit';
import RecurringNotificationsIcon from '@mui/icons-material/Alarm';
import RecurringNotificationsList from './RecurringNotificationsList';

export default {
  list: RecurringNotificationsList,
  edit: RecurringNotificationsEdit,
  // create: RecurringNotificationsCreate,
  icon: RecurringNotificationsIcon,
};
