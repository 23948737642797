import * as React from 'react';

import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { IRecurringNotifications } from '../../types';

const Title = () => {
  const record = useRecordContext<IRecurringNotifications>();
  const translate = useTranslate();
  return (
    <span>
      {translate('resources.recurring_notifications.name')}{' '}
      {record ? `"${record.name}"` : ''}
    </span>
  );
};

const RecurringNotificationsEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<Title />}>
      <SimpleForm>
        <TextInput
          source="name"
          label={translate('resources.recurring_notifications.fields.name')}
          validate={[required()]}
          disabled
          inputProps={{
            maxLength: 30,
          }}
          style={{ width: '100%', maxWidth: 400 }}
        />
        <TextInput
          source="uniqueId"
          label={translate('resources.recurring_notifications.fields.uniqueId')}
          validate={[required()]}
          inputProps={{
            maxLength: 30,
          }}
          style={{ width: '100%', maxWidth: 400 }}
        />
        <TextInput
          source="notificationTitle"
          label={translate(
            'resources.recurring_notifications.fields.notificationTitle'
          )}
          validate={[required()]}
          inputProps={{
            maxLength: 30,
          }}
          style={{ width: '100%', maxWidth: 400 }}
        />
        <TextInput
          source="notificationText"
          label={translate(
            'resources.recurring_notifications.fields.notificationText'
          )}
          validate={[required()]}
          inputProps={{
            maxLength: 100,
          }}
          fullWidth
        />
        <p>
          {'{date}'} - дата, {'{balance}'} - баланс
        </p>
        <br />
        <BooleanInput
          fullWidth
          label={translate('resources.recurring_notifications.fields.active')}
          source="active"
          defaultValue={true}
        />
        <ReferenceArrayInput
          source="discountGroup"
          reference="discounts"
          perPage={100}
        >
          <SelectArrayInput
            label={translate('resources.recurring_notifications.fields.sendTo')}
            optionText={(discount) => `${discount.name}`}
            // disabled={true}
          />
        </ReferenceArrayInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.uniqueId === 'promocodes-rozn' ||
            formData.uniqueId === 'promocodes-vip' ? (
              <ReferenceInput
                source="promocode"
                reference="promocodes"
                perPage={1000}
              >
                <SelectInput
                  optionText="name"
                  label={translate(
                    'resources.recurring_notifications.fields.promocode'
                  )}
                  validate={[required()]}
                  style={{ width: '100%', maxWidth: 400 }}
                />
              </ReferenceInput>
            ) : null
          }
        </FormDataConsumer>
        {/* <ReferenceInput source="sendTo" reference="customers" perPage={1000}>
          <SelectInput
            label={translate('resources.recurring_notifications.fields.sendTo')}
            optionText={(customer) =>
              `${customer.name} ${customer?.companyName ?? ''} (${
                customer.phone
              })`
            }
            resettable
            disabled={true}
          />
        </ReferenceInput> */}
      </SimpleForm>
    </Edit>
  );
};

export default RecurringNotificationsEdit;
