import * as React from 'react';

import {
  ArrayField,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  FormDataConsumer,
  FunctionField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  maxValue,
  minValue,
  number,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { IMoneyMovement, IPromocodes } from '../../types';

import { InputAdornment } from '@mui/material';
import { format } from 'date-fns';

const Title = () => {
  const record = useRecordContext<IPromocodes>();
  return <span>Промокод {record ? `"${record.name}"` : ''}</span>;
};

const EditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const PromocodesEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<Title />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput
          label={translate('resources.promocodes.fields.name')}
          source="name"
          validate={[required()]}
          style={{ width: '100%', maxWidth: 400 }}
        />
        <TextInput
          label={translate('resources.promocodes.fields.code')}
          source="code"
          validate={[required()]}
          style={{ width: '100%', maxWidth: 400 }}
        />
        <BooleanInput
          label="Скидка в % / грн"
          source="isAbsoluteValue"
          fullWidth
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.isAbsoluteValue ? (
              <NumberInput
                label={translate('resources.promocodes.fields.absoluteValue')}
                source="absoluteValue"
                step={0.1}
                max={10000}
                min={0}
                format={(v) => parseFloat(v)}
                parse={(v) => v.toString().split('.')[0]}
                defaultValue={0}
                validate={[required(), number(), minValue(0), maxValue(10000)]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">грн</InputAdornment>
                  ),
                }}
                style={{ width: '100%', maxWidth: 400 }}
              />
            ) : (
              <NumberInput
                label={translate('resources.promocodes.fields.value')}
                source="value"
                step={0.1}
                max={100}
                min={0}
                format={(v) => parseFloat(v)}
                parse={(v) => v.toString().split('.')[0]}
                defaultValue={0}
                validate={[required(), number(), minValue(0), maxValue(100)]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                style={{ width: '100%', maxWidth: 400 }}
              />
            )
          }
        </FormDataConsumer>
        <ReferenceInput
          source="discountGroup"
          reference="discounts"
          validate={[required()]}
          perPage={1000}
        >
          <SelectInput
            optionText="name"
            label={translate('resources.promocodes.fields.discountGroup')}
            style={{ width: '100%', maxWidth: 400 }}
          />
        </ReferenceInput>
        <DateInput
          label={translate('resources.promocodes.fields.expireDate')}
          source="expireDate"
          style={{ width: '100%', maxWidth: 400 }}
          validate={[
            required(),
            minValue(format(new Date(), 'yyyy-MM-dd')),
            // maxValue(format(new Date(), 'yyyy-MM-dd')),
          ]}
        />
        <p>Иcтория использования</p>
        <ArrayField source="history" label="История использования" fullWidth>
          <Datagrid>
            <DateField source="date" label="Дата" />
            <ReferenceField
              source="clientId"
              label={translate('resources.promocodes.fields.client')}
              reference="customers"
            >
              <FunctionField
                render={(record: IMoneyMovement) =>
                  record
                    ? `${record.name} ${
                        record.companyName && '(' + record.companyName + ')'
                      } - ${record.phone}`
                    : ''
                }
              />
              {/* <TextField source="name" /> */}
            </ReferenceField>
          </Datagrid>
        </ArrayField>
      </SimpleForm>
    </Edit>
  );
};

export default PromocodesEdit;
