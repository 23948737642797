import { NumberField, NumberFieldProps, useRecordContext } from 'react-admin';

import { Order } from '../../types';
import React from 'react';

const SummField: React.FC<NumberFieldProps> = (props) => {
  const record = useRecordContext<Order>();
  const { source } = props;

  const customValue =
    record?.totalSummInUSD -
    record?.promocodeValue / Number(record?.items[0].rate) -
    record?.bonuses;

  return (
    <NumberField {...props} record={{ ...record, [source!]: customValue }} />
  );
};

export default SummField;
