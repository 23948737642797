import * as React from 'react';

import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  Labeled,
  PasswordInput,
  SelectInput,
  SimpleForm,
  TextInput,
  maxLength,
  minLength,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { IUser } from '../../types';

const validatePassword = [minLength(6)];

const Title = () => {
  const record = useRecordContext<IUser>();
  const translate = useTranslate();
  return (
    <span>
      {translate('resources.users.editText')} {record ? `"${record.name}"` : ''}
    </span>
  );
};

const UsersEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<Title />}>
      <SimpleForm>
        <TextInput
          source="name"
          label={translate('resources.users.fields.name')}
          validate={[required()]}
        />
        <TextInput
          source="email"
          label={translate('resources.users.fields.email')}
        />
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <SelectInput
                // disabled={formData.role === 'admin'}
                source="role"
                label={translate('resources.users.fields.role')}
                choices={[
                  {
                    id: 'admin',
                    name: translate('resources.users.fields.roles.admin'),
                  },
                  {
                    id: 'finance',
                    name: translate('resources.users.fields.roles.finance'),
                  },
                  {
                    id: 'manager',
                    name: translate('resources.users.fields.roles.manager'),
                  },
                  {
                    id: 'user',
                    name: translate('resources.users.fields.roles.user'),
                  },
                ]}
                validate={[required()]}
              />
            );
          }}
        </FormDataConsumer>
        <TextInput
          source="phone"
          label={translate('resources.users.fields.phone')}
          validate={[required(), maxLength(10), minLength(10)]}
          parse={(v) => v.replace(/[^\d]/, '')}
          inputProps={{
            maxLength: 10,
          }}
        />
        <BooleanInput
          source="chPass"
          label={translate('resources.users.fields.chPass')}
        />
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData }) =>
            formData.chPass && (
              <Labeled label={translate('resources.users.fields.pass_text')}>
                <PasswordInput
                  source="password"
                  label={translate('resources.users.fields.password')}
                  initiallyVisible
                  disabled={!formData.chPass}
                  validate={validatePassword}
                />
              </Labeled>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default UsersEdit;
