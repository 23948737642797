import * as React from 'react';

import {
  BooleanInput,
  Edit,
  FileField,
  FileInput,
  Labeled,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  maxLength,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';

import { IPrice } from '../../types';
import { Typography } from '@mui/material';

const Title = () => {
  const record = useRecordContext<IPrice>();
  const translate = useTranslate();
  return (
    <span>
      {translate('resources.price.name')} {record ? `"${record.name}"` : ''}
    </span>
  );
};

const EditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const PriceEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<Title />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput
          source="name"
          label={translate('resources.price.fields.name')}
          validate={[required()]}
          // disabled
        />
        <Typography component="span" variant="body2">
          {translate('resources.price.fields.theme')}
        </Typography>
        <TextInput
          source="topic"
          label={translate('resources.price.fields.topic')}
          resettable
        />
        <TextInput
          source="description"
          label={translate('resources.price.fields.description')}
          multiline
          resettable
          fullWidth
          validate={[required(), maxLength(1500)]}
        />
        <FileInput
          source="image"
          label={translate('resources.price.fields.image')}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          // validate={[required()]}
        >
          <FileField
            source="image"
            label={translate('resources.price.fields.image')}
            title="title"
          />
        </FileInput>
        <Labeled label={translate('resources.price.fields.curr_file')}>
          <FileField source="file" title="name" target="_blank" />
        </Labeled>
        {/* <SelectInput
        label="День недели"
        source="day"
        sele
        choices={[
          { id: 'monday', name: 'Понедельник' },
          { id: 'tuesday', name: 'Вторник' },
          { id: 'wensday', name: 'Среда' },
          { id: 'thursday', name: 'Четверг' },
          { id: 'friday', name: 'Пятница' },
          { id: 'saturday', name: 'Суббота' },
        ]}
        validate={[required()]}
      /> */}
        <BooleanInput
          source="active"
          label={translate('resources.price.fields.active_alt')}
          fullWidth
          defaultValue={true}
        />
      </SimpleForm>
    </Edit>
  );
};

export default PriceEdit;
